import {useCallback, useContext, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {Parcel} from '../../types';
import {ParcelRequest} from '../../types/request';
import {parcelSchema} from '../../schema';
import {CollectionContext} from '../../contexts';

type Props = {
  isEditable?: boolean
  parcel: Parcel
  onSave?: (parcelRequest: ParcelRequest) => void
}

const ParcelCard = ({
                      isEditable = true,
                      parcel,
                      onSave
                    }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {collections} = useContext(CollectionContext);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (parcelRequest: ParcelRequest) => {
    setModalIsOpen(false);
    if (onSave) {
      await onSave(parcelRequest);
    }
  }, [onSave]);

  const initialValues: ParcelRequest = useMemo(() => ({
    parcelNumber: parcel.parcelNumber,
    countyId: parcel.countyId,
    localUnit: parcel.localUnit,
    address: {
      directionPrefix: parcel.address.directionPrefix,
      directionSuffix: parcel.address.directionSuffix,
      streetNumber: parcel.address.streetNumber,
      streetName: parcel.address.streetName,
      streetType: parcel.address.streetType,
      unit: parcel.address.unit,
      city: parcel.address.city,
      state: 'MI',
      zip: parcel.address.zip
    }
  }), [parcel]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Property Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {isEditable && permissions.hasWriteAccess && <Button color="primary"
                                                                 size="sm"
                                                                 onClick={() => setModalIsOpen(true)}>
              Edit
            </Button>
            }
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-20">Parcel #</th>
            <th className="w-20">County</th>
            <th className="w-20">City/Township</th>
            <th className="w-40">Parcel Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{parcel.parcelNumber}</td>
            <td>{parcel.county.displayName}</td>
            <td>{parcel.localUnit}</td>
            <td>{parcel.address.fullDisplay}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="xl">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Property Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={parcelSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikInput autoFocus
                             name="parcelNumber"
                             labelText="Parcel #"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="countyId"
                              labelText="County">
                  <option value="">Select</option>
                  {collections?.counties.map(county =>
                    <option value={county.id} key={county.id}>
                      {county.displayName}
                    </option>)
                  }
                </FormikSelect>
              </Col>
              <Col sm="4">
                <FormikInput name="localUnit"
                             labelText="City/Township"/>
              </Col>
            </Row>
            <Row>
              <Col sm="2">
                <FormikInput name="address.directionPrefix"
                             labelText="Dir. Prefix"/>
              </Col>
              <Col sm="2">
                <FormikInput name="address.streetNumber"
                             labelText="Street #"/>
              </Col>
              <Col sm="3">
                <FormikInput name="address.streetName"
                             labelText="Street Name"/>
              </Col>
              <Col sm="2">
                <FormikInput name="address.streetType"
                             labelText="Street Type"/>
              </Col>
              <Col sm="2">
                <FormikInput name="address.directionSuffix"
                             labelText="Dir. Suffix"/>
              </Col>
              <Col sm="1">
                <FormikInput name="address.unit"
                             labelText="APT #"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="address.city"
                             labelText="City"/>
              </Col>
              <Col sm="4">
                <FormikInput name="address.state"
                             disabled={true}
                             labelText="State Abbr"/>
              </Col>
              <Col sm="4">
                <FormikInput name="address.zip"
                             maxLength={10}
                             labelText="Zip Code"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="primary"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default ParcelCard;