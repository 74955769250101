import * as Yup from 'yup';

import * as messages from '../../messages';
import {validateUniqueYears} from '../validationUtils';

export default Yup.object({
  yearsDenied: Yup.array(Yup.object({
    year: Yup.number()
      .required(messages.REQUIRED),
    allowedPercent: Yup.number()
      .typeError(messages.REQUIRED)
      .required(messages.REQUIRED),
    proposed: Yup.string()
      .oneOf(['VALID', 'DENY'], 'Must be valid or deny.')
      .required(messages.REQUIRED)
  })).max(4)
    .test('uniqueYears', '', validateUniqueYears)
});