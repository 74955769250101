import * as Yup from 'yup';

import * as messages from '../messages';

export default Yup.object({
  directionPrefix: Yup.string()
    .nullable(),
  directionSuffix: Yup.string()
    .nullable(),
  streetName: Yup.string()
    .nullable(),
  streetNumber: Yup.string()
    .nullable(),
  streetType: Yup.string()
    .nullable(),
  city: Yup.string()
    .nullable(),
  state: Yup.string()
    .nullable(),
  zip: Yup.string()
    .max(10, messages.MAX_CHARACTERS_10)
    .nullable(),
  unit: Yup.string()
    .nullable()
});