import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDatePicker, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {formatBoolean, formatDate} from '../../util';
import {AppealResponse} from '../../types';
import {AppealRequest} from '../../types/request';
import {appealInformationSchema} from '../../schema/appeal';

type Props = {
  appeal: AppealResponse
  onSave: (appealRequest: AppealRequest) => void
  onDownloadFile: (path: string) => void
}

type Values = {
  receivedOn?: Date
  noticeSentOn?: Date
  timely?: boolean | string
}

const AppealInformationCard = ({
                                 appeal,
                                 onSave,
                                 onDownloadFile
                               }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (values: Values) => {
    const appealRequest = {
      ...appeal,
      ...values
    } as AppealRequest;
    await onSave(appealRequest);
    setModalIsOpen(false);
  }, [appeal, onSave]);

  const initialValues = useMemo(() => ({
    receivedOn: appeal.receivedOn,
    noticeSentOn: appeal.noticeSentOn,
    timely: appeal.timely
  }), [appeal]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Appeal Information
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Date Appeal Received</th>
            <th>Timely</th>
            <th>Appeal Received Notice Date</th>
            <th className="hide-print">Appeal Received Notice</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatDate(appeal.receivedOn)}&nbsp;</td>
            <td>{formatBoolean(appeal.timely)}&nbsp;</td>
            <td>{formatDate(appeal.noticeSentOn)}&nbsp;</td>
            <td className="hide-print">
              {appeal.noticeLetter && <Button color="link"
                                              onClick={() => onDownloadFile(appeal!.noticeLetter!.downloadLink)}
                                              className="p-0 line-height-1">
                {appeal.noticeLetter.displayFilename}
              </Button>}
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Appeal Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={appealInformationSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikDatePicker name="receivedOn"
                                  labelText="Date Appeal Received"/>
              </Col>
              <Col sm="4">
                <FormikDatePicker name="noticeSentOn"
                                  labelText="Appeal Received Notice Date"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="timely"
                              labelText="Timely">
                  <option value="">Select</option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </FormikSelect>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default AppealInformationCard;