import * as Yup from 'yup';

import {YupFile} from '@reasoncorp/kyber-js';

import * as messages from '../messages';

export default Yup.object({
  file: new YupFile()
    .maxFileSize(10 * 1000 * 1000, messages.MAX_FILE_SIZE)
    .acceptedFileTypes(['CSV', 'DOC', 'DOCX', 'EML', 'JPG', 'JPEG', 'msg', 'PDF', 'PNG', 'TXT', 'XLS', 'XLSM', 'XLSX'], messages.INVALID_UPLOAD_FILETYPE)
    .schema()
    .required(messages.REQUIRED)
});