import {useCallback, useContext, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';
import {Link} from 'react-router-dom';

import {FormikCheckboxGroup, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {formatDate} from '../../util';
import {DenialResponse} from '../../types';
import {DenialInformationRequest, DenialRequest} from '../../types/request';
import {denialInformationSchema} from '../../schema/denial';
import {CollectionContext} from '../../contexts';

type Props = {
  denial: DenialResponse
  onSave: (denialRequest: DenialRequest) => void
}

const DenialInformationCard = ({
                                 denial,
                                 onSave
                               }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {collections} = useContext(CollectionContext);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (denialInformationRequest: DenialInformationRequest) => {
    setModalIsOpen(false);
    const denialRequest = {
      ...denial,
      ...denialInformationRequest
    } as DenialRequest;
    await onSave(denialRequest);
  }, [denial, onSave]);

  const initialValues: DenialInformationRequest = useMemo(() => ({
    majorReason: denial.majorReason || '',
    minorReason: denial.minorReason || '',
    nonResident: denial.nonResident
  }), [denial]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Denial Information
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   size="sm"
                                                   onClick={() => setModalIsOpen(true)}>
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive>
        <thead>
          <tr>
            <th>Letter Date</th>
            <th>Treasury Reason</th>
            <th>Minor Reason</th>
            <th>Appeal #</th>
            <th>Bona Fide #</th>
            <th>Out of State Exemption</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatDate(denial.letterSentOn)}</td>
            <td>{denial.majorReason}</td>
            <td>{denial.minorReason}</td>
            <td>
              <Link to={`/appeals/${denial.appealId}`}>
                {denial.appealFileNumber}
              </Link>
            </td>
            <td>
              <Link to={`/bona-fides/${denial.bonaFideId}`}>
                {denial.bonaFideFileNumber}
              </Link>
            </td>
            <td>{denial.nonResident ? 'Yes' : 'No'}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Denial Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={denialInformationSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormikSelect autoFocus
                              name="majorReason"
                              labelText="Major Reason">
                  <option value="">Select</option>
                  {collections?.majorReasons.map(majorReason =>
                    <option value={majorReason} key={majorReason}>{majorReason}</option>)
                  }
                </FormikSelect>
              </Col>
              <Col sm="6">
                <FormikSelect name="minorReason"
                              labelText="Minor Reason">
                  <option value="">Select</option>
                  {collections?.minorReasons.map(minorReason =>
                    <option value={minorReason} key={minorReason}>{minorReason}</option>)
                  }
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col>
                <Col>
                  <FormikCheckboxGroup type="switch"
                                       checkboxes={[
                                         {name: 'nonResident', labelText: 'Out of State Exemption'}
                                       ]}/>
                </Col>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="success"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary" onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default DenialInformationCard;