import * as Yup from 'yup';

import * as messages from '../../messages';
import {validateUniqueSeasonPerYear} from '../validationUtils';

export default Yup.object({
  denialYears: Yup.array(Yup.object({
    season: Yup.string()
      .required(messages.REQUIRED),
    year: Yup.number()
      .required(messages.REQUIRED),
    taxableValue: Yup.number()
      .required(messages.REQUIRED)
      .typeError(messages.MUST_BE_NUMBER),
    amountBilledSchoolOperating: Yup.number()
      .required(messages.REQUIRED)
      .typeError(messages.MUST_BE_NUMBER),
    taxDue: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable(),
    interestDue: Yup.number()
      .typeError(messages.MUST_BE_NUMBER)
      .nullable()
  }))
    .test('uniqueYears', '', validateUniqueSeasonPerYear)
});