import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object({
  issueBill: Yup.bool(),
  issueBillReason: Yup.string().when('issueBill', {
    is: false,
    then: Yup.string().required(messages.REQUIRED)
  }),
  issueBillReasonOther: Yup.string().when('issueBillReason', {
    is: 'OTHER',
    then: Yup.string().required(messages.REQUIRED)
  })
});