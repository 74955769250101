import {useCallback, useContext, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {AppealResponse} from '../../types';
import {formatBoolean, formatDate} from '../../util';
import {appealAnalystReviewSchema} from '../../schema/appeal';
import {CollectionContext} from '../../contexts';
import {AppealAnalystReviewRequest} from '../../types/request';

type Props = {
  appeal: AppealResponse
  onSave: (appealAnalystReviewRequest: AppealAnalystReviewRequest) => void
}

const AppealAnalystReviewCard = ({
                                   appeal,
                                   onSave
                                 }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {collections} = useContext(CollectionContext);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (appealAnalystReviewRequest: AppealAnalystReviewRequest) => {
    await onSave(appealAnalystReviewRequest);
    setModalIsOpen(false);
  }, [onSave]);

  const initialValues: AppealAnalystReviewRequest = useMemo(() => ({
    analystId: appeal.analystId || '',
    analystRecommendation: appeal.analystRecommendation,
    hearingRequired: appeal.hearingRequired,
    supervisorId: appeal.supervisorId || ''
  }), [appeal]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Analyst Appeal Review and Recommendation
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess &&
              <Button color="primary"
                      onClick={() => setModalIsOpen(true)}
                      size="sm">
                Edit
              </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Analyst Assigned</th>
            <th>Analyst Recommendation</th>
            <th>Hearing Needed</th>
            <th>Date of Analyst Recommendation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{appeal.analystName}&nbsp;</td>
            <td>{appeal.analystRecommendation}&nbsp;</td>
            <td>{formatBoolean(appeal.hearingRequired)}&nbsp;</td>
            <td>{formatDate(appeal.analystRecommendationOn)}&nbsp;</td>
          </tr>
          <tr>
            <th scope="row">Supervisor</th>
            <td colSpan={4}>{appeal.supervisorName}</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Analyst Appeal Review and Recommendation
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={appealAnalystReviewSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormikSelect autoFocus
                              name="analystId"
                              labelText="Analyst Assigned">
                  <option value="">Select</option>
                  {collections?.analysts.map(analyst =>
                    <option key={analyst.id} value={analyst.id}>{analyst.fullName}</option>)}
                </FormikSelect>
              </Col>
              <Col sm="6">
                <FormikSelect name="analystRecommendation"
                              labelText="Analyst Recommendation">
                  <option value="">Select</option>
                  {collections?.appealDecisions.map(appealDecision =>
                    <option key={appealDecision} value={appealDecision}>{appealDecision}</option>)}
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormikSelect name="hearingRequired"
                              labelText="Hearing Needed">
                  <option value="">Select</option>
                  <option value="true">YES</option>
                  <option value="false">NO</option>
                </FormikSelect>
              </Col>
              <Col sm="6">
                <FormikSelect name="supervisorId"
                              labelText="Submit to Supervisor">
                  <option value="">Select</option>
                  {collections?.supervisors.map(supervisor =>
                    <option key={supervisor.id} value={supervisor.id}>{supervisor.fullName}</option>)}
                </FormikSelect>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default AppealAnalystReviewCard;