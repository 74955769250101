import {BlobResponse, ProgressReport, StatusReport, TrajectoryReport} from '../types';
import {proServApi} from './apiUtils';

export const findStatusReport = (): Promise<StatusReport> => proServApi.getWithJsonResponse(`/reports/status`);

export const downloadStatusReport = (): Promise<BlobResponse> => proServApi.getWithBlobResponse(
  `/reports/status/download`
);

export const findProgressReport = (): Promise<ProgressReport> => proServApi.getWithJsonResponse(
  `/reports/progress`
);

export const downloadProgressReport = (): Promise<BlobResponse> => proServApi.getWithBlobResponse(
  `/reports/progress/download`
);

export const findTrajectoryReport = (): Promise<TrajectoryReport> => proServApi.getWithJsonResponse(
  `/reports/trajectory`
);

export const downloadTrajectoryReport = (): Promise<BlobResponse> => proServApi.getWithBlobResponse(
  `/reports/trajectory/download`
);