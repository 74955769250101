import {useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Modal, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikNumberInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {interestWaiverDenialInformationSchema} from '../../schema/interestWaiver';
import {InterestWaiverResponse} from '../../types';
import {InterestWaiverDenialInformationRequest, InterestWaiverRequest} from '../../types/request';
import {formatMoney} from '../../util';

type Props = {
  interestWaiver: InterestWaiverResponse
  onSave: (interestWaiverRequest: InterestWaiverRequest) => void
}

const InterestWaiverDenialInformationCard = ({
                                               interestWaiver,
                                               onSave
                                             }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = async (interestWaiverDenialInformationRequest: InterestWaiverDenialInformationRequest) => {
    const interestWaiverRequest = {
      ...interestWaiver,
      ...interestWaiverDenialInformationRequest
    } as InterestWaiverRequest;
    await onSave(interestWaiverRequest);
    setModalIsOpen(false);
  };

  const initialValues: InterestWaiverDenialInformationRequest = {
    deniedBy: interestWaiver.deniedBy,
    billedBy: interestWaiver.billedBy,
    requestedAmountToWaive: interestWaiver.requestedAmountToWaive || null
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Denial and Interest Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th style={{width: '26%'}}>Denied By</th>
            <th style={{width: '26%'}}>Bill Issued By</th>
            <th style={{width: '48%'}}>Total Interest Treasury Should Consider Waiving</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{interestWaiver.deniedBy}&nbsp;</td>
            <td>{interestWaiver.billedBy}&nbsp;</td>
            <td>{formatMoney(interestWaiver.requestedAmountToWaive)}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Denial and Interest Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={interestWaiverDenialInformationSchema}>
        {(formikProps) => (<>
          <CardBody>
            <Row>
              <Col sm="6">
                <FormikSelect name="deniedBy"
                              labelText="Denied By"
                              autoFocus>
                  <option value="">Select</option>
                  {['TREASURY', 'COUNTY', 'ASSESSOR'].map(deniedBy =>
                    <option value={deniedBy} key={deniedBy}>{deniedBy}</option>)
                  }
                </FormikSelect>
              </Col>
              <Col sm="6">
                <FormikSelect name="billedBy"
                              labelText="Bill Issued By">
                  <option value="">Select</option>
                  {['TREASURY', 'COUNTY', 'ASSESSOR'].map(deniedBy =>
                    <option value={deniedBy} key={deniedBy}>{deniedBy}</option>)
                  }
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <FormikNumberInput name="requestedAmountToWaive"
                                   formGroupClass="mb-0"
                                   labelText="Total Interest Treasury Should Consider Waiving"/>
              </Col>
            </Row>
          </CardBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="success"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default InterestWaiverDenialInformationCard;