import {DocumentResponse, RelatedParcelRecord} from '../types';
import {proServApi} from './apiUtils';

export const findRelatedRecords = (id: number): Promise<RelatedParcelRecord[]> => proServApi.getWithJsonResponse(
  `/parcels/${id}/related-records`
);

export const uploadDocument = (id: number, fileData: FormData): Promise<DocumentResponse> => proServApi.postWithJsonResponse(
  `/parcels/${id}/documents`,
  {
    body: fileData
  }
);