import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDatePicker, FormikInput, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {formatDate} from '../../util';
import {BonaFideCancellation, BonaFideResponse} from '../../types';
import {BonaFideRequest} from '../../types/request';
import {bonaFideCancellationSchema} from '../../schema/bonaFide';

type Props = {
  bonaFide: BonaFideResponse
  onSave: (bonaFideRequest: BonaFideRequest) => void
}

const BonaFideCancellationCard = ({
                                    bonaFide,
                                    onSave
                                  }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (cancellation: BonaFideCancellation) => {
    setModalIsOpen(false);
    const bonaFideRequest = {
      ...bonaFide,
      cancellation
    } as BonaFideRequest;
    await onSave(bonaFideRequest);
  }, [bonaFide, onSave]);

  const initialValues: BonaFideCancellation = useMemo(() => ({
    canceledOn: bonaFide.cancellation?.canceledOn,
    cancellationDecision: bonaFide.cancellation?.cancellationDecision || 's',
    cancellationExplanation: bonaFide.cancellation?.cancellationExplanation || ''
  }), [bonaFide]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Cancellation
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-33">Date Received</th>
            <th className="w-33">Decision</th>
            <th className="w-33">Explanation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatDate(bonaFide.cancellation?.canceledOn)}&nbsp;</td>
            <td>{bonaFide.cancellation?.cancellationDecision}&nbsp;</td>
            <td>{bonaFide.cancellation?.cancellationExplanation}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Cancellation
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={bonaFideCancellationSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikDatePicker name="canceledOn"
                                  labelText="Date Received"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="cancellationDecision"
                              labelText="Decision">
                  <option value="">Select</option>
                  <option key="APPROVED" value="APPROVED">APPROVED</option>
                  <option key="DENIED" value="DENIED">DENIED</option>
                </FormikSelect>
              </Col>
              <Col sm="4">
                <FormikInput name="cancellationExplanation"
                             labelText="Explanation"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success" className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default BonaFideCancellationCard;