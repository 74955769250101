import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikDatePicker, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {formatDate} from '../../util';
import {BonaFideDenialInformation, BonaFideResponse} from '../../types';
import {BonaFideRequest} from '../../types/request';
import {bonaFideDenialInformationSchema} from '../../schema/bonaFide';

type Props = {
  bonaFide: BonaFideResponse
  onSave: (bonaFideRequest: BonaFideRequest) => void
}

const BonaFideDenialInformationCard = ({
                                         bonaFide,
                                         onSave
                                       }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (denialInformation: BonaFideDenialInformation) => {
    setModalIsOpen(false);
    const bonaFideRequest = {
      ...bonaFide,
      denialInformation
    } as BonaFideRequest;
    await onSave(bonaFideRequest);
  }, [bonaFide, onSave]);

  const initialValues: BonaFideDenialInformation = useMemo(() => ({
    deniedBy: bonaFide.denialInformation?.deniedBy,
    deniedOn: bonaFide.denialInformation?.deniedOn
  }), [bonaFide]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Denial and Interest Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-50">Denied By</th>
            <th className="w-50">Date of Denial</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{bonaFide.denialInformation?.deniedBy}&nbsp;</td>
            <td>{formatDate(bonaFide.denialInformation?.deniedOn)}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Denial and Interest Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={bonaFideDenialInformationSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormikSelect autoFocus
                              name="deniedBy"
                              labelText="Denied By">
                  <option value="">Select</option>
                  {['TREASURY', 'COUNTY', 'ASSESSOR'].map(deniedBy =>
                    <option value={deniedBy} key={deniedBy}>{deniedBy}</option>)
                  }
                </FormikSelect>
              </Col>
              <Col sm="6">
                <FormikDatePicker name="deniedOn"
                                  labelText="Date of Denial"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success" className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default BonaFideDenialInformationCard;