import {Call} from '../types';
import {CallRequest} from '../types/request';
import {proServApi} from './apiUtils';

export const findAll = (parcelId: number): Promise<Call[]> => proServApi.getWithJsonResponse(
  `/parcels/${parcelId}/calls`
);

export const create = (parcelId: number, callRequest: CallRequest): Promise<Call> => proServApi.postWithJsonResponse(
  `/parcels/${parcelId}/calls`,
  {
    body: JSON.stringify(callRequest)
  }
);