import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object({
  receivedOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  noticeSentOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  timely: Yup.boolean()
    .nullable()
});