import {Note} from '../types';
import {NoteRequest} from '../types/request';
import {proServApi} from './apiUtils';

export const findAll = (parcelId: number): Promise<Note[]> => proServApi.getWithJsonResponse(
  `/parcels/${parcelId}/notes`
);

export const create = (parcelId: number, noteRequest: NoteRequest): Promise<Note> => proServApi.postWithJsonResponse(
  `/parcels/${parcelId}/notes`,
  {
    body: JSON.stringify(noteRequest)
  }
);
