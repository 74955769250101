import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object({
  docketNumber: Yup.string()
    .nullable(),
  receivedOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  hearingOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  responseDueOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  proposedJudgement: Yup.string()
    .nullable(),
  proposedJudgementOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  finalOpinionOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  finalOpinion: Yup.string()
    .nullable(),
  refereeName: Yup.string()
    .nullable(),
  analystName: Yup.string()
    .nullable(),
  attorneyName: Yup.string()
    .nullable(),
  legalFirmName: Yup.string()
    .nullable(),
  legalFirmAddress: Yup.string()
    .nullable(),
  treasuryMotion: Yup.bool()
    .nullable(),
  treasuryResponseOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  treasuryExceptionFiled: Yup.bool()
    .nullable(),
  treasuryExceptionOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  finalOpinionJudgeName: Yup.string()
    .nullable(),
  reconsiderationDecision: Yup.string()
    .nullable(),
  petitionerMotion: Yup.bool()
    .nullable(),
  issue: Yup.string()
    .nullable()
});