import {useCallback, useContext, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikRadioGroup, FormikSelect, useUserContext} from '@reasoncorp/kyber-js';

import {BonaFideResponse, BonaFideReview} from '../../types';
import {BonaFideRequest} from '../../types/request';
import {bonaFideReviewSchema} from '../../schema/bonaFide';
import {formatBoolean} from '../../util';
import {CollectionContext} from '../../contexts';

type Props = {
  bonaFide: BonaFideResponse,
  onSave: (bonaFideRequest: BonaFideRequest) => void
}

const BonaFideReviewCard = ({
                              bonaFide,
                              onSave
                            }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {collections} = useContext(CollectionContext);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (bonaFideReview: BonaFideReview) => {
    const bonaFideRequest = {
      ...bonaFide,
      review: bonaFideReview
    } as BonaFideRequest;
    setModalIsOpen(false);
    await onSave(bonaFideRequest);
  }, [bonaFide, onSave]);

  const initialValues: BonaFideReview = useMemo(() => ({
    issueBill: bonaFide.review?.issueBill?.toString(),
    issueBillReason: bonaFide.review?.issueBillReason || '',
    issueBillReasonOther: bonaFide.review?.issueBillReasonOther || ''
  }), [bonaFide]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Review - File Documents
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>Should the bill be issued?</th>
            <th>Provided Reason</th>
            <th>Other Reason</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatBoolean(Boolean(bonaFide.review?.issueBill))}&nbsp;</td>
            <td>{bonaFide.review?.issueBillReason}&nbsp;</td>
            <td>{bonaFide.review?.issueBillReasonOther}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Review
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={bonaFideReviewSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col>
                <FormikRadioGroup
                  labelText="Should the bill be issued?"
                  name="issueBill"
                  inline
                  radioButtons={[
                    {value: 'true', labelText: 'Yes'},
                    {value: 'false', labelText: 'No'}
                  ]}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikSelect name="issueBillReason"
                              disabled={formikProps.values.issueBill === 'true' || formikProps.values.issueBill === true}
                              labelText="Provide Reason">
                  <option value="">Select</option>
                  {collections?.bonaFideIssueBillReasons.map(reason =>
                    <option value={reason} key={reason}>{reason}</option>)
                  }
                </FormikSelect>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="issueBillReasonOther"
                             disabled={!formikProps.values.issueBill || formikProps.values.issueBillReason !== 'OTHER'}
                             labelText="Other Reason"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row className="justify-content-end">
              <Col>
                <Button color="success"
                        className="mr-2"
                        disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                        onClick={formikProps.submitForm}>
                  Save
                </Button>
                <Button color="secondary"
                        onClick={() => setModalIsOpen(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default BonaFideReviewCard;
