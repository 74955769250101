import {useCallback, useEffect, useMemo, useState} from 'react';
import {Badge, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {Link} from 'react-router-dom';

import {ButtonIcon, CustomTable, downloadUtils, ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {DenialNavBar} from '../components/denials';
import {denialLetterApi} from '../api';
import {DenialLetterBatchLite} from '../types';
import {formatDate} from '../util';
import * as messages from '../messages';

const LetterBatches = () => {
  const [denialLetterBatches, setDenialLetterBatches] = useState<DenialLetterBatchLite[]>([]);
  const [loadingState, setLoadingState] = useState({loading: false, loadError: false, processing: false});
  const {showErrorAlert, showSuccessAlert} = useAlerts();

  const loadDenialLetterBatches = useCallback(async () => {
    try {
      setLoadingState({loading: true, loadError: false, processing: false});
      const denialLetterBatches = await denialLetterApi.findBatches();
      setDenialLetterBatches(denialLetterBatches);
      setLoadingState({loading: false, loadError: false, processing: false});

      // Refresh every 20 seconds
      if (denialLetterBatches.filter(denialLetterBatches => denialLetterBatches.status === 'PROCESSING').length > 0) {
        setTimeout(() => loadDenialLetterBatches().then(), 1000 * 20);
      }
    } catch (e) {
      setLoadingState({loading: false, loadError: true, processing: false});
      showErrorAlert(messages.API_FAILURE);
    }
  }, [showErrorAlert]);

  useEffect(() => {
    void loadDenialLetterBatches();
  }, [loadDenialLetterBatches]);

  const handleDownloadFile = useCallback(async (id: number) => {
    try {
      await downloadUtils.downloadFile(denialLetterApi.getBatchSignedUrl(id));
    } catch (e) {
      showErrorAlert(messages.LETTER_BATCH_DOWNLOAD_FAILURE);
    }
  }, [showErrorAlert]);

  const handleRecreateLetterBatch = useCallback(async (id: number) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      await denialLetterApi.recreateDenialLetterBatch(id);
      showSuccessAlert(messages.LETTER_BATCH_RECREATE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: false, processing: false});

      await loadDenialLetterBatches();
    } catch (e) {
      showErrorAlert(messages.LETTER_BATCH_RECREATE_FAILURE);
      setLoadingState({loading: false, loadError: false, processing: false});
    }
  }, [showSuccessAlert, showErrorAlert, loadDenialLetterBatches]);

  const renderStatusBadge = useMemo(() => (denialLetterBatch: DenialLetterBatchLite) => {
    let color = 'danger';
    if (denialLetterBatch.status === 'PROCESSING') {
      color = 'primary';
    } else if (denialLetterBatch.status === 'ACTIVE') {
      color = 'success';
    }

    return <Badge color={color}>
      {denialLetterBatch.status}
    </Badge>;
  }, []);

  const tableProps = useMemo(() => ({
    items: denialLetterBatches,
    headers: [
      {title: 'Letter Batch ID', className: 'text-center'},
      {title: 'Created', className: 'text-center'},
      {title: 'Sent On ', className: 'text-center'},
      {title: '# Letters', className: 'text-center'},
      {title: 'Status', className: 'text-center'},
      {title: 'View', className: 'text-center'},
      {title: 'Recreate', className: 'text-center'},
      {title: 'Download', className: 'text-center'}
    ],
    noResultsMessage: 'No denial letter batches.',
    paginatorConfig: {
      perPage: 100
    }, renderRow: (denialLetterBatch: DenialLetterBatchLite) => {
      return <tr key={denialLetterBatch.id}>
        <td className="text-center align-middle">
          {denialLetterBatch.fileNumber}
        </td>
        <td className="text-center align-middle">
          {formatDate(denialLetterBatch.createdAt)}
        </td>
        <td className="text-center align-middle">
          {formatDate(denialLetterBatch.lettersSentOn)}
        </td>
        <td className="text-center align-middle">
          {denialLetterBatch.expectedItemCount}
        </td>
        <td className="text-center align-middle">
          {renderStatusBadge(denialLetterBatch)}
        </td>
        <td className="text-center align-middle">
          <Link to={`/letters/batches/${denialLetterBatch.id}`}>
            View
          </Link>
        </td>
        <td className="text-center text-success align-middle">
          <ButtonIcon icon="redo"
                      disabled={denialLetterBatch.status !== 'ACTIVE'}
                      title={`Recreate Denial Letter Batch ${denialLetterBatch.id} Letter`}
                      ariaLabel={`Recreate Denial Letter Batch ${denialLetterBatch.id} Letter`}
                      onClick={() => handleRecreateLetterBatch(denialLetterBatch.id)}/>
        </td>
        <td className="text-center text-primary">
          <ButtonIcon icon="paperclip"
                      disabled={denialLetterBatch.status !== 'ACTIVE'}
                      ariaLabel={`Download batch ${denialLetterBatch.id}`}
                      title={`Download batch ${denialLetterBatch.id}`}
                      onClick={() => handleDownloadFile(denialLetterBatch.id)}/>
        </td>
      </tr>;
    }
  }), [
    denialLetterBatches,
    handleDownloadFile,
    handleRecreateLetterBatch,
    renderStatusBadge
  ]);

  return <Container fluid>
    <Row className="mb-4">
      <Col className="d-flex justify-content-end">
        <DenialNavBar/>
      </Col>
    </Row>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && !loadingState.loadError && <Row>
      <Col>
        <Card>
          <CardHeader>Denial Letter Batches</CardHeader>
          <CustomTable {...tableProps}/>
        </Card>
      </Col>
    </Row>}
  </Container>;
};

export default LetterBatches;