import {DenialLetter, DenialLetterBatch, DenialLetterBatchLite, DenialSearchResponse} from '../types';
import {DenialLetterBatchRequest, DenialSearchRequest} from '../types/request';
import {proServApi} from './apiUtils';

export const findDenialsWithoutLetters = (denialSearchRequest: DenialSearchRequest): Promise<DenialSearchResponse[]> => proServApi.postWithJsonResponse(
  `/denials/letters`,
  {
    body: JSON.stringify(denialSearchRequest)
  }
);

export const findBatches = (): Promise<DenialLetterBatchLite[]> => proServApi.getWithJsonResponse(
  `/denials/letters/batches`
);

export const findBatch = (id: number): Promise<DenialLetterBatch> => proServApi.getWithJsonResponse(
  `/denials/letters/batches/${id}`
);

export const createBatch = (denialLetterBatchRequest: DenialLetterBatchRequest): Promise<DenialLetterBatch> => proServApi.postWithJsonResponse(
  `/denials/letters/batches`,
  {
    body: JSON.stringify(denialLetterBatchRequest)
  }
);

export const getSignedUrl = (id: number): Promise<string> => proServApi.getWithTextResponse(
  `/denials/letters/${id}/signed-url`
);

export const getBatchSignedUrl = (id: number): Promise<string> => proServApi.getWithTextResponse(
  `/denials/letters/batches/${id}/signed-url`
);

export const deleteLetter = (id: number): Promise<string> => proServApi.deleteWithTextResponse(
  `/denials/letters/${id}`
);

export const deleteBatch = (id: number): Promise<string> => proServApi.deleteWithTextResponse(
  `/denials/letters/batches/${id}`
);

export const recreate = (id: number): Promise<DenialLetter> => proServApi.putWithJsonResponse(
  `/denials/letters/${id}`
);

export const recreateDenialLetterBatch = (id: number): Promise<DenialLetterBatch> => proServApi.putWithJsonResponse(
  `/denials/letters/batches/${id}`
);