import * as Yup from 'yup';

import * as messages from '../messages';

export default Yup.object({
  year: Yup.number(),
  county: Yup.number().nullable(),
  majorReason: Yup.string(),
  lettersSentOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .required(messages.REQUIRED),
  perPage: Yup.number()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED)
    .max(400, messages.MUST_BE_LESS_THAN_OR_EQUAL_TO_400)
});