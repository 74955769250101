import {Navigate, Route, Routes} from 'react-router-dom';

import {Alerts, Footer, NotFound, SkipNav, useUserContext} from '@reasoncorp/kyber-js';

import {ProServAppBar} from './components';
import {
  Appeal,
  Appeals,
  BonaFide,
  BonaFides,
  Denial,
  Denials,
  Imports,
  ImportsHistory,
  InterestWaiver,
  InterestWaivers,
  LetterBatch,
  LetterBatches,
  Letters,
  QualifiedError,
  QualifiedErrors,
  Reports
} from './containers';

import './font-awesome-icons';

const App = () => {
  const {loadingUser, currentUser, permissions} = useUserContext();

  return <>
    <SkipNav/>
    <ProServAppBar currentUser={currentUser}
                   hasImportAndLetterAccess={permissions.hasImportAndLetterAccess}/>
    {!loadingUser && currentUser &&
      <main role="main" id="content">
        <Routes>
          <Route path="/" element={<Navigate replace to="/denials"/>}/>
          <Route path="/appeals" element={<Appeals/>}/>
          <Route path="/appeals/:id" element={<Appeal/>}/>
          <Route path="/bona-fides" element={<BonaFides/>}/>
          <Route path="/bona-fides/:id" element={<BonaFide/>}/>
          <Route path="/denials" element={<Denials/>}/>
          <Route path="/denials/:id" element={<Denial/>}/>
          <Route path="/interest-waivers" element={<InterestWaivers/>}/>
          <Route path="/interest-waivers/:id" element={<InterestWaiver/>}/>
          <Route path="/qualified-errors" element={<QualifiedErrors/>}/>
          <Route path="/qualified-errors/:id" element={<QualifiedError/>}/>
          {permissions.hasImportAndLetterAccess && <>
            <Route path="/imports" element={<Imports/>}/>
            <Route path="/imports/history/:batchId" element={<ImportsHistory/>}/>
            <Route path="/imports/history" element={<ImportsHistory/>}/>
            <Route path="/letters" element={<Letters/>}/>
            <Route path="/letters/batches" element={<LetterBatches/>}/>
            <Route path="/letters/batches/:id" element={<LetterBatch/>}/>
            <Route path="/reports" element={<Reports/>}/>
          </>}
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </main>
    }
    <Footer brand="State of Michigan"
            brandLink="https://michigan.gov"
            footerLinks={[
              {name: 'MI.gov', to: 'https://michigan.gov'},
              {name: 'Home', to: '/'},
              {name: 'Contact', to: 'https://www.michigan.gov/treasury/0,4679,7-121--8483--,00.html'},
              {name: 'Policies', to: 'https://www.michigan.gov/policies'}
            ]}
    />
    <Alerts/>
  </>;
};

export default App;
