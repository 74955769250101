import * as Yup from 'yup';

import parcelSchema from '../parcelSchema';
import *  as messages from '../../messages';

export default Yup.object({
  parcel: parcelSchema,
  majorReason: Yup.string()
    .required(messages.REQUIRED),
  minorReason: Yup.string()
    .required(messages.REQUIRED)
});