import {ReactNode, useEffect, useState} from 'react';

import {useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import CollectionContext from './CollectionContext';
import {CollectionResponse} from '../types';
import {collectionApi} from '../api';
import * as messages from '../messages';

type  Props = {
  children: ReactNode
}

const CollectionProvider = ({
                              children
                            }: Props) => {
  const [collections, setCollections] = useState<CollectionResponse | undefined>(undefined);
  const {loadingUser} = useUserContext();
  const {showErrorAlert} = useAlerts();

  useEffect(() => {
    const loadCollections = async () => {
      try {
        if (!loadingUser) {
          const collections = await collectionApi.find();
          setCollections(collections);
        }
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
      }
    };
    
    void loadCollections();
  }, [loadingUser, showErrorAlert]);

  return <CollectionContext.Provider value={{collections}}>
    {children}
  </CollectionContext.Provider>;
};

export default CollectionProvider;