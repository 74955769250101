import * as Yup from 'yup';

export default Yup.object({
  completedRequestForPreQualifiedError: Yup.boolean(),
  paymentReceived: Yup.boolean(),
  letterReceived: Yup.boolean(),
  ownershipVerified: Yup.boolean(),
  occupancyVerified: Yup.boolean(),
  signedAssessorStatement: Yup.boolean(),
  grantRequest: Yup.string().nullable(),
  grantRequestPartialYears: Yup.number().nullable(),
  grantRequestReason: Yup.string().nullable(),
  grantRequestExplanation: Yup.string().nullable()
});