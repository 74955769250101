import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object({
  parcelNumber: Yup.string(),
  ownerNames: Yup.string(),
  majorReason: Yup.string(),
  fileNumber: Yup.string(),
  appealFileNumber: Yup.string(),
  countyId: Yup.number().nullable(),
  appealStatus: Yup.string(),
  parcelAddress: Yup.string(),
  letterSentOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable()
});