import * as Yup from 'yup';

import * as messages from '../messages';
import {validateUniqueSeasonPerYear} from './validationUtils';

export default Yup.array(Yup.object({
  season: Yup.string()
    .nullable(),
  decision: Yup.string()
    .oneOf(['VALID', 'DENIED'], 'Must be Valid or Denied.')
    .nullable(),
  year: Yup.number(),
  deniedPercent: Yup.number()
    .min(0, messages.MUST_BE_GREATER_THAN_0)
    .max(100, messages.MUST_BE_LESS_THAN_OR_EQUAL_TO_100)
    .nullable()
})).max(8)
  .test('uniqueYears', '', validateUniqueSeasonPerYear);