import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object({
  certificationErrorType: Yup.string().nullable(),
  certificationErrorExplanation: Yup.string().when('certificationErrorType', {
    is: 'OTHER',
    then: Yup.string().required(messages.REQUIRED),
    otherwise: Yup.string().nullable()
  })
});