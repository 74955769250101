import {useCallback, useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {ConfirmationModal, downloadUtils, ProgressIndicator, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import {
  CallsCard,
  DocumentsCard,
  MailingAddressCard,
  NotesCard,
  OwnerCard,
  ParcelCard,
  PowerOfAttorneyCard,
  RelatedParcelRecordsCard
} from '../components/shared';
import {
  AppealAnalystReviewCard,
  AppealBillingInformationCard,
  AppealCourtHearingCard,
  AppealDenialInformationCard,
  AppealInformalHearingCard,
  AppealInformationCard,
  AppealMttHearingCard,
  AppealStatusButton,
  AppealSupervisorReviewCard
} from '../components/appeals';
import {Address, AppealResponse, Call, CourtHearing, DocumentResponse, MttHearing, Note} from '../types';
import {
  AppealAnalystReviewRequest,
  AppealRequest,
  AppealStatusRequest,
  InformalHearingRequest,
  OwnerRequest,
  PowerOfAttorneyRequest
} from '../types/request';
import {appealApi, callApi, documentApi, noteApi} from '../api';
import * as messages from '../messages';

const Appeal = () => {
  const {permissions} = useUserContext();
  const id = Number(useParams<{id: string}>().id);
  const [rejectModalIsOpen, setRejectModalIsOpen] = useState(false);
  const [acceptModalIsOpen, setAcceptModalIsOpen] = useState(false);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [appeal, setAppeal] = useState<AppealResponse | undefined>(undefined);
  const [loadingState, setLoadingState] = useState({loading: false, loadError: false, processing: false});
  const [notes, setNotes] = useState<Note[]>([]);
  const [calls, setCalls] = useState<Call[]>([]);
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);

  useEffect(() => {
    const loadAppeal = async () => {
      setLoadingState({loadError: false, loading: true, processing: false});
      try {
        const appeal = await appealApi.find(id);
        setAppeal(appeal);
        const [notes, calls, documents] = await Promise.all([
          noteApi.findAll(appeal.denial.parcel.id),
          callApi.findAll(appeal.denial.parcel.id),
          appealApi.findDocuments(appeal.id)
        ]);
        setNotes(notes);
        setCalls(calls);
        setDocuments(documents);
        setLoadingState({loadError: false, loading: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState({loadError: true, loading: false, processing: false});
      }
    };
    loadAppeal().then();
  }, [id, showErrorAlert]);

  const reloadAppeal = useCallback(async () => {
    try {
      const appeal = await appealApi.find(id);
      setAppeal(appeal);
      const [notes, calls, documents] = await Promise.all([
        noteApi.findAll(appeal.denial.parcel.id),
        callApi.findAll(appeal.denial.parcel.id),
        appealApi.findDocuments(appeal.id)
      ]);
      setNotes(notes);
      setCalls(calls);
      setDocuments(documents);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setLoadingState({loadError: true, loading: false, processing: false});
    }
  }, [id, showErrorAlert]);

  const handleAcceptAppeal = useCallback(async () => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      const appeal = await appealApi.accept(id);
      setAppeal(appeal);
      setAcceptModalIsOpen(false);
      showSuccessAlert(messages.APPEAL_ACCEPT_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_ACCEPT_FAILURE);
    }
    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showSuccessAlert, showErrorAlert, id]);

  const handleRejectAppeal = useCallback(async () => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      const appeal = await appealApi.reject(id);
      setAppeal(appeal);
      setRejectModalIsOpen(false);
      showSuccessAlert(messages.APPEAL_REJECT_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_REJECT_FAILURE);
    }
    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showErrorAlert, showSuccessAlert, id]);

  const handleSave = useCallback(async (appealRequest: AppealRequest) => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      await appealApi.save(id, appealRequest);
      const [appeal, documents] = await Promise.all([
        appealApi.find(id),
        appealApi.findDocuments(id)
      ]);
      setAppeal(appeal);
      setDocuments(documents);
      showSuccessAlert(messages.APPEAL_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_SAVE_FAILURE);
    }
    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showErrorAlert, showSuccessAlert, id]);

  const handleSaveStatus = useCallback(async (appealStatusRequest: AppealStatusRequest) => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      await appealApi.saveStatus(id, appealStatusRequest);
      const [appeal, documents] = await Promise.all([
        appealApi.find(id),
        appealApi.findDocuments(id)
      ]);
      setAppeal(appeal);
      setDocuments(documents);
      showSuccessAlert(messages.APPEAL_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_SAVE_FAILURE);
    }
    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showErrorAlert, showSuccessAlert, id]);

  const handleSaveAnalystReview = useCallback(async (appealAnalystReviewRequest: AppealAnalystReviewRequest) => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      await appealApi.saveAnalystReview(id, appealAnalystReviewRequest);
      const [appeal, documents] = await Promise.all([
        appealApi.find(id),
        appealApi.findDocuments(id)
      ]);
      setAppeal(appeal);
      setDocuments(documents);
      showSuccessAlert(messages.APPEAL_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_SAVE_FAILURE);
    }
    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showErrorAlert, showSuccessAlert, id]);

  const handleSaveSupervisorReview = useCallback(async (formData: FormData) => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      await appealApi.saveSupervisorReview(id, formData);
      const [appeal, documents] = await Promise.all([
        appealApi.find(id),
        appealApi.findDocuments(id)
      ]);
      setAppeal(appeal);
      setDocuments(documents);
      showSuccessAlert(messages.APPEAL_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_SAVE_FAILURE);
    }
    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showErrorAlert, showSuccessAlert, id]);

  const handleSaveCourtHearing = useCallback(async (courtHearing: CourtHearing) => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      await appealApi.saveCourtHearing(id, courtHearing);
      const [appeal, documents] = await Promise.all([
        appealApi.find(id),
        appealApi.findDocuments(id)
      ]);
      setAppeal(appeal);
      setDocuments(documents);
      showSuccessAlert(messages.APPEAL_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_SAVE_FAILURE);
    }
    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showErrorAlert, showSuccessAlert, id]);

  const handleDownloadFile = useCallback(async (path: string) => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      await downloadUtils.downloadFile(documentApi.getSignedUrl(path));
    } catch (e) {
      showErrorAlert(messages.DOCUMENT_DOWNLOAD_FAILURE);
    }

    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showErrorAlert]);

  const handleSaveMttHearing = useCallback(async (mttHearing: MttHearing) => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      await appealApi.saveMttHearing(id, mttHearing);
      const [appeal, documents] = await Promise.all([
        appealApi.find(id),
        appealApi.findDocuments(id)
      ]);
      setAppeal(appeal);
      setDocuments(documents);
      showSuccessAlert(messages.APPEAL_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_SAVE_FAILURE);
    }

    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showErrorAlert, showSuccessAlert, id]);

  const handleSaveInformalHearing = useCallback(async (informalHearingRequest: InformalHearingRequest) => {
    try {
      setLoadingState({loadError: false, loading: false, processing: true});
      await appealApi.saveInformalHearing(id, informalHearingRequest);
      const [appeal, documents] = await Promise.all([
        appealApi.find(id),
        appealApi.findDocuments(id)
      ]);
      setAppeal(appeal);
      setDocuments(documents);
      showSuccessAlert(messages.APPEAL_SAVE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.APPEAL_SAVE_FAILURE);
    }
    setLoadingState({loadError: false, loading: false, processing: false});
  }, [showErrorAlert, showSuccessAlert, id]);

  const handlePowerOfAttorneySave = useCallback((powerOfAttorney: PowerOfAttorneyRequest) => handleSave({
    ...appeal,
    powerOfAttorney
  } as AppealRequest), [appeal, handleSave]);

  const handleMailingAddressSave = useCallback((mailingAddress: Address) => handleSave({
    ...appeal,
    mailingAddress
  } as AppealRequest), [appeal, handleSave]);

  const handleOwnerSave = useCallback((owner: OwnerRequest) => handleSave({
    ...appeal,
    owner
  } as AppealRequest), [appeal, handleSave]);

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading && appeal && <>
      <Row>
        <Col>
          <h1 className="h5 mb-0">Appeal: {appeal.fileNumber}</h1>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="align-self-center" sm="3">
          Status: {appeal.statusDisplayValue}
        </Col>
        <Col className="d-flex justify-content-end"
             sm="9">
          {permissions.hasAdminAccess && <AppealStatusButton appeal={appeal}
                                                             onSave={handleSaveStatus}/>}
          <Button color="primary"
                  disabled={loadingState.processing}
                  onClick={() => window.print()}
                  className="mr-2">
            Print
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="12">
          <ParcelCard isEditable={false}
                      parcel={appeal.denial.parcel}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <Row className="mb-4">
            <Col>
              <OwnerCard owner={appeal.owner}
                         onSave={handleOwnerSave}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <PowerOfAttorneyCard powerOfAttorney={appeal.powerOfAttorney}
                                   onSave={handlePowerOfAttorneySave}/>
            </Col>
          </Row>
        </Col>
        <Col sm="6">
          <Row className="mb-4">
            <Col>
              <MailingAddressCard mailingAddress={appeal.mailingAddress}
                                  onSave={handleMailingAddressSave}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <AppealDenialInformationCard appeal={appeal}/>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <AppealBillingInformationCard appeal={appeal}
                                        onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <AppealInformationCard appeal={appeal}
                                 onDownloadFile={handleDownloadFile}
                                 onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <AppealAnalystReviewCard appeal={appeal}
                                   onSave={handleSaveAnalystReview}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <AppealSupervisorReviewCard appeal={appeal}
                                      onSave={handleSaveSupervisorReview}
                                      openAcceptModal={() => setAcceptModalIsOpen(true)}
                                      openRejectModal={() => setRejectModalIsOpen(true)}
                                      onDownloadFile={handleDownloadFile}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <AppealInformalHearingCard informalHearing={appeal.informalHearing}
                                     appeal={appeal}
                                     onSave={handleSaveInformalHearing}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <AppealMttHearingCard mttHearing={appeal.mttHearing}
                                onSave={handleSaveMttHearing}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <AppealCourtHearingCard courtHearing={appeal.courtHearing}
                                  onSave={handleSaveCourtHearing}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-6">
          <RelatedParcelRecordsCard parcelId={appeal.denial.parcel.id}/>
        </Col>
      </Row>
      <NotesCard notes={notes}
                 parcelId={appeal.denial.parcel.id}
                 onSaveSuccess={reloadAppeal}/>
      <CallsCard calls={calls}
                 parcelId={appeal.denial.parcel.id}
                 onSaveSuccess={reloadAppeal}/>
      <DocumentsCard documents={documents}
                     onSuccess={reloadAppeal}
                     parcelId={appeal.denial.parcel.id}/>

      <ConfirmationModal isOpen={acceptModalIsOpen}
                         title="Accept Appeal"
                         confirmCallback={handleAcceptAppeal}
                         cancelCallback={() => setAcceptModalIsOpen(false)}
                         confirmButtonColor="success"
                         confirmButtonText="Yes">
        Are you sure you wish to accept Appeal <strong className="text-danger">{appeal.fileNumber}</strong>?
      </ConfirmationModal>

      <ConfirmationModal isOpen={rejectModalIsOpen}
                         title="Reject Appeal"
                         confirmCallback={handleRejectAppeal}
                         cancelCallback={() => setRejectModalIsOpen(false)}
                         confirmButtonColor="success"
                         confirmButtonText="Yes">
        Are you sure you wish to reject Appeal <strong className="text-danger">{appeal.fileNumber}</strong>?
      </ConfirmationModal>
    </>}
  </Container>;
};

export default Appeal;