import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object({
  docketNumber: Yup.string()
    .nullable(),
  sentOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  ruling: Yup.string()
    .nullable()
});