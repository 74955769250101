import {memo} from 'react';
import {Card, CardHeader, Col, Row, Table} from 'reactstrap';
import {Link} from 'react-router-dom';

import {formatDate} from '../../util';
import {AppealResponse} from '../../types';

type Props = {
  appeal: AppealResponse
}

const AppealDenialInformationCard = ({
                                       appeal
                                     }: Props) => <Card>
  <CardHeader>
    <Row>
      <Col className="align-self-center" sm="6">
        Denial Information
      </Col>
    </Row>
  </CardHeader>
  <Table responsive bordered>
    <thead>
      <tr>
        <th>Letter Date</th>
        <th>Treasury Reason</th>
        <th>Minor Reason</th>
        <th>Denial ID</th>
        <th>Years Denied</th>
        <th>Percent Allowed</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{formatDate(appeal.denial?.letterSentOn)}</td>
        <td>{appeal.denial.majorReason}</td>
        <td>{appeal.denial.minorReason}</td>
        <td>
          <Link to={`/denials/${appeal.denial.id}`}>
            {appeal.denial.fileNumber}
          </Link>
        </td>
        <td>{appeal.denial.allDeniedYears.join(', ')}</td>
        <td>{appeal.denial.yearsDeniedPercents}</td>
      </tr>
    </tbody>
  </Table>
</Card>;

export default memo(AppealDenialInformationCard);