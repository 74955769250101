import * as Yup from 'yup';

export default Yup.object({
  ownerFirstName: Yup.string()
    .nullable(),
  ownerLastName: Yup.string()
    .nullable(),
  ownerMiddleName: Yup.string()
    .nullable(),
  coOwnerFirstName: Yup.string()
    .nullable(),
  coOwnerLastName: Yup.string()
    .nullable(),
  coOwnerMiddleName: Yup.string()
    .nullable(),
  ownerCareOf: Yup.string()
    .nullable(),
  ownerCompany: Yup.string()
    .nullable()
});