import {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';

import {ProgressIndicator, useAlerts} from '@reasoncorp/kyber-js';

import {Address, Call, DocumentResponse, Note, QualifiedErrorResponse} from '../types';
import {
  CallsCard,
  DocumentsCard,
  NotesCard,
  ParcelCard,
  RecordNavBar,
  RelatedParcelRecordsCard,
  SimpleMailingAddressCard
} from '../components/shared';
import {
  QualifiedErrorCertificationCard,
  QualifiedErrorFileInformationCard,
  QualifiedErrorOwnerCard,
  QualifiedErrorReviewCard
} from '../components/qualifiedErrors';
import {callApi, noteApi, qualifiedErrorApi} from '../api';
import * as messages from '../messages';
import {ParcelRequest, QualifiedErrorRequest} from '../types/request';

const QualifiedError = () => {
  const id = Number(useParams<{id: string}>().id);
  const [loadingState, setLoadingState] = useState({loading: false, loadError: false, processing: false});
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const [qualifiedError, setQualifiedError] = useState<QualifiedErrorResponse | undefined>(undefined);
  const [calls, setCalls] = useState<Call[]>([]);
  const [notes, setNotes] = useState<Note[]>([]);
  const [documents, setDocuments] = useState<DocumentResponse[]>([]);

  useEffect(() => {
    const loadQualifiedError = async () => {
      setLoadingState({loading: true, loadError: false, processing: false});
      try {
        const [qualifiedError, documents] = await Promise.all([
          qualifiedErrorApi.find(id),
          qualifiedErrorApi.findDocuments(id)
        ]);
        setQualifiedError(qualifiedError);
        setDocuments(documents);
        const [notes, calls] = await Promise.all([
          noteApi.findAll(qualifiedError.parcel.id),
          callApi.findAll(qualifiedError.parcel.id)
        ]);
        setNotes(notes);
        setCalls(calls);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
        setLoadingState({loading: false, loadError: true, processing: false});
      }
    };

    void loadQualifiedError();
  }, [id, showErrorAlert]);

  const loadQualifiedError = useCallback(async () => {
    try {
      const [qualifiedError, documents] = await Promise.all([
        qualifiedErrorApi.find(id),
        qualifiedErrorApi.findDocuments(id)
      ]);
      setQualifiedError(qualifiedError);
      setDocuments(documents);
      const [notes, calls] = await Promise.all([
        noteApi.findAll(qualifiedError.parcel.id),
        callApi.findAll(qualifiedError.parcel.id)
      ]);
      setNotes(notes);
      setCalls(calls);
    } catch (e) {
      showErrorAlert(messages.API_FAILURE);
      setLoadingState({loading: false, loadError: true, processing: false});
    }
  }, [showErrorAlert, id]);

  const handleSave = useCallback(async (qualifiedErrorRequest: QualifiedErrorRequest) => {
    try {
      setLoadingState({loading: false, loadError: false, processing: true});
      const qualifiedError = await qualifiedErrorApi.save(id, qualifiedErrorRequest);
      setQualifiedError(qualifiedError);
      showSuccessAlert(messages.QUALIFIED_ERROR_SAVE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: false, processing: false});
    } catch (e) {
      showErrorAlert(messages.QUALIFIED_ERROR_SAVE_SUCCESSFUL);
      setLoadingState({loading: false, loadError: true, processing: false});
    }
  }, [id, showErrorAlert, showSuccessAlert]);

  const handleParcelSave = useCallback((parcel: ParcelRequest) => handleSave({
    ...qualifiedError,
    parcel
  } as QualifiedErrorRequest), [qualifiedError, handleSave]);

  const handleMailingAddressSave = useCallback((mailingAddress: Address) => handleSave({
    ...qualifiedError,
    mailingAddress
  } as QualifiedErrorRequest), [handleSave, qualifiedError]);

  const renderButtons = useMemo(() => () => <>
    <Button color="primary"
            disabled={loadingState.processing}
            onClick={() => window.print()}
            className="mr-2">
      Print
    </Button>
  </>, [loadingState.processing]);

  return <Container fluid>
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loadError && !loadingState.loading && qualifiedError && <>
      <Row className="mb-4">
        <Col className="align-self-center" sm="3">
          <h1 className="h5 mb-0">Qualified Error: {qualifiedError.fileNumber}</h1>
        </Col>
        <Col className="d-flex justify-content-end" sm="9">
          <RecordNavBar parcel={qualifiedError.parcel}
                        currentRecordType="QUALIFIED_ERROR"
                        createButtonsDisabled={true}
                        onRecordSave={loadQualifiedError}
                        renderButtons={renderButtons}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <QualifiedErrorFileInformationCard qualifiedError={qualifiedError}
                                             onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <ParcelCard parcel={qualifiedError.parcel}
                      onSave={handleParcelSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <QualifiedErrorOwnerCard qualifiedError={qualifiedError}
                                   onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-6">
          <SimpleMailingAddressCard mailingAddress={qualifiedError.mailingAddress}
                                    onSave={handleMailingAddressSave}/>
        </Col>
        <Col className="col-6">
          <QualifiedErrorCertificationCard qualifiedError={qualifiedError}
                                           onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <QualifiedErrorReviewCard qualifiedError={qualifiedError}
                                    onSave={handleSave}/>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm="6">
          <RelatedParcelRecordsCard parcelId={qualifiedError.parcel.id}/>
        </Col>
      </Row>
      <NotesCard notes={notes}
                 parcelId={qualifiedError.parcel.id}
                 onSaveSuccess={loadQualifiedError}/>
      <CallsCard calls={calls}
                 parcelId={qualifiedError.parcel.id}
                 onSaveSuccess={loadQualifiedError}/>
      <DocumentsCard documents={documents}
                     parcelId={qualifiedError.parcel.id}
                     onSuccess={loadQualifiedError}/>
    </>}
  </Container>;
};

export default QualifiedError;
