import {memo, useCallback, useMemo} from 'react';

import {AppBar, SOMLogo, User, UserProfile} from '@reasoncorp/kyber-js';

import {ssoApi} from '../api';
import config from '../config';

type Props = {
  currentUser?: User
  hasImportAndLetterAccess?: boolean
}

const ProServAppBar = ({
                         currentUser,
                         hasImportAndLetterAccess
                       }: Props) => {
  const handleProfileClick = useCallback(() => window.location.href = `${config.sso.webUrl}/user-profile`, []);

  const handleSignOut = useCallback(async () => {
    await ssoApi.signOut();
  }, []);

  const renderUserProfile = useMemo(() => currentUser ? () =>
    <UserProfile handleProfileClick={handleProfileClick}
                 handleSignOut={handleSignOut}/> : undefined, [currentUser, handleSignOut, handleProfileClick]);

  const routes = useMemo(() => {
    const routes = [
      {name: 'Denials', to: '/denials'},
      {name: 'Appeals', to: '/appeals'},
      {name: 'Bona Fides', to: '/bona-fides'},
      {name: 'Interest Waivers', to: '/interest-waivers'},
      {name: 'Qualified Errors', to: '/qualified-errors'}
    ];

    if (hasImportAndLetterAccess) {
      routes.push({name: 'Reports', to: '/reports'});
    }

    return routes;
  }, [hasImportAndLetterAccess]);

  const appBarProps = useMemo(() => ({
    brandLink: `${config.sso.webUrl}/dashboard`,
    brandImage: SOMLogo,
    brandImageAlt: 'ProSuite',
    appName: 'ProServ',
    organizationName: 'The Department of Treasury',
    environmentName: config.envName !== 'prod' ? config.envName : undefined,
    routes,
    renderUserProfile
  }), [renderUserProfile, routes]);

  return (
    <AppBar {...appBarProps}/>
  );
};

export default memo(ProServAppBar);
