import {formatBoolean, formatDate, formatDateTime, formatDecimal, formatMoney, formatPercent} from './formatting';

const sum = (values: (number | undefined | null | String)[]) => {
  let total = 0;
  values.forEach((value) => {
    if (value !== undefined && value !== null) {
      value = Number(value);
      total += value;
    }
  });

  return total;
};

const availableDenialYears = Array.from({length: 7})
  .map((_, i) => new Date().getFullYear() - i)
  .filter(year => year >= 2015);

const availableBonaFideDenialYears = Array.from({length: 8})
  .map((_, i) => new Date().getFullYear() - i)
  .filter(year => year >= 2014);

const isNumber = (value: any) => {
  if (value === '' || value === undefined || value == null) {
    return false;
  } else if (!isNaN(Number(value))) {
    return true;
  }
};

export {
  availableDenialYears,
  availableBonaFideDenialYears,
  formatBoolean,
  formatDate,
  formatDateTime,
  formatDecimal,
  formatMoney,
  formatPercent,
  isNumber,
  sum
};