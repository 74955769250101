import {useCallback, useMemo, useState} from 'react';
import {Button, Card, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';
import {Link} from 'react-router-dom';

import {FormikInput, useUserContext} from '@reasoncorp/kyber-js';

import {AppealResponse} from '../../types';
import {AppealRequest} from '../../types/request';
import {appealBillingSchema} from '../../schema/appeal';

type Props = {
  appeal: AppealResponse,
  onSave: (appealRequest: AppealRequest) => void
}

type Values = {
  assessmentNumber?: string
}

const AppealBillingInformationCard = ({
                                        appeal,
                                        onSave
                                      }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = useCallback(async (values: Values) => {
    const appealRequest = {
      ...appeal,
      ...values
    } as AppealRequest;
    await onSave(appealRequest);
    setModalIsOpen(false);
  }, [appeal, onSave]);

  const initialValues = useMemo(() => ({
    assessmentNumber: appeal.assessmentNumber
  }), [appeal]);

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col className="align-self-center" sm="6">
            Billing Information
          </Col>
          <Col className="justify-content-end d-flex" sm="6">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th>Bona Fide Number</th>
            <th>Assessment Numbers</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {appeal.denial.bonaFideId &&
                <Link to={`/bona-fides/${appeal.denial.bonaFideId}`}>
                  {appeal.denial.bonaFideFileNumber}
                </Link>}
              &nbsp;
            </td>
            <td>{appeal.assessmentNumber}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="md">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Billing Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={appealBillingSchema}>
        {(formikProps) => (<>
          <ModalBody>
            <Row>
              <Col>
                <FormikInput autoFocus
                             name="assessmentNumber"
                             labelText="Assessment Numbers"/>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="success"
                    className="mr-2"
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default AppealBillingInformationCard;