import * as Yup from 'yup';

import {YupPhone} from '@reasoncorp/kyber-js';

import * as messages from '../messages';

export default Yup.object({
  callerName: Yup.string().required(messages.REQUIRED),
  text: Yup.string().required(messages.REQUIRED),
  phoneNumber: new YupPhone().schema().required(messages.REQUIRED)
});