import {kyberFontAwesomeLibrary} from '@reasoncorp/kyber-js';
import {
  faCheckCircle,
  faFilePdf,
  faPaperclip,
  faPlusCircle,
  faRedo,
  faSpinner,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

kyberFontAwesomeLibrary.add(
  faCheckCircle,
  faPaperclip,
  faPlusCircle,
  faRedo,
  faFilePdf,
  faSpinner,
  faTimesCircle
);
