import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object({
  majorReason: Yup.string()
    .required(messages.REQUIRED),
  minorReason: Yup.string()
    .required(messages.REQUIRED),
  nonResident: Yup.bool()
    .nullable()
});