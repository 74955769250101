import * as Yup from 'yup';

import *  as messages from '../../messages';

export default Yup.object({
  errorType: Yup.string(),
  otherExplanation: Yup.string().when('errorType', {
    is: 'OTHER',
    then: Yup.string().required(messages.REQUIRED),
    otherwise: Yup.string().nullable()
  })
});
