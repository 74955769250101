import * as Yup from 'yup';

import parcelSchema from '../parcelSchema';
import * as messages from '../../messages';

export default Yup.object({
  parcel: parcelSchema,
  receivedOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .required(messages.REQUIRED)
    .nullable(),
  numberOfYearsRequested: Yup.number()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED)
});