import {memo} from 'react';
import {Button, Nav, NavItem, NavLink} from 'reactstrap';
import {NavLink as RouterLink, useLocation} from 'react-router-dom';

import {useUserContext} from '@reasoncorp/kyber-js';

const navItems = [
  {text: 'Send Letters', path: '/letters'},
  {text: 'Letter Batches', path: '/letters/batches'},
  {text: 'Imports', path: '/imports'},
  {text: 'Import History', path: '/imports/history'}
];

type Props = {
  displayCreateButton?: boolean
  openCreateModal?: () => void
}

const DenialNavBar = ({
                        displayCreateButton = false,
                        openCreateModal
                      }: Props) => {
  const location = useLocation();
  const {permissions} = useUserContext();

  return <>
    {permissions.hasImportAndLetterAccess && <Nav>
      {navItems.map(navItem => <NavItem active={location.pathname === navItem.path} key={navItem.text}>
        <NavLink to={navItem.path} tag={RouterLink}>
          {navItem.text}
        </NavLink>
      </NavItem>)}
    </Nav>}
    {displayCreateButton && openCreateModal && permissions.hasWriteAccess &&
      <Button color="primary"
              onClick={openCreateModal}>
        Create Denial
      </Button>
    }
  </>;
};

export default memo(DenialNavBar);