import * as Yup from 'yup';

import * as messages from '../../messages';
import yearDeniedReviewsSchema from '../yearDeniedReviewsSchema';

export default Yup.object({
  docketNumber: Yup.string()
    .nullable(),
  hearingOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  sentToHearingsOn: Yup.date()
    .required(messages.REQUIRED)
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  determinationOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
    .nullable(),
  analystId: Yup.number()
    .nullable(),
  refereeName: Yup.string()
    .nullable(),
  decision: Yup.string()
    .nullable(),
  yearDeniedReviews: yearDeniedReviewsSchema
});
