import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {AlertsProvider, ProSuiteJurisdiction, ProSuiteRole, ssoUtils, User, UserProvider} from '@reasoncorp/kyber-js';
import './index.scss';
import config from './config';
import {ssoApi} from './api';
import App from './App';
import {CollectionProvider} from './contexts';
import * as messages from './messages';

if (config.envName !== 'prod') {
  document.title = `${config.envName.toUpperCase()} - ${messages.APP_TITLE}`;
}

const setPermissions = (currentUser?: User) => ({
  hasImportAndLetterAccess: ssoUtils.hasProSuiteAdminRole(currentUser, ProSuiteJurisdiction.PROSERV_APP) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.ANALYST) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.SUPERVISOR),
  hasWriteAccess: ssoUtils.hasProSuiteAdminRole(currentUser, ProSuiteJurisdiction.PROSERV_APP) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.TECHNICIAN) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.ANALYST) ||
    ssoUtils.hasJurisdictionAndRole(currentUser, ProSuiteJurisdiction.PROSERV_APP, ProSuiteRole.SUPERVISOR),
  hasAdminAccess: ssoUtils.hasProSuiteAdminRole(currentUser, ProSuiteJurisdiction.PROSERV_APP)
});

if (window.navigator.msSaveOrOpenBlob !== undefined) {
  window.location.href = config.sso.webUrl + '/browser-not-supported.html';
} else {
  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <BrowserRouter>
      <UserProvider ssoApi={ssoApi} setPermissions={setPermissions}>
        <AlertsProvider>
          <CollectionProvider>
            <App/>
          </CollectionProvider>
        </AlertsProvider>
      </UserProvider>
    </BrowserRouter>
  );
}