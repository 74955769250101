import {useCallback, useContext, useMemo} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {useNavigate} from 'react-router-dom';

import {FormikInput, FormikSelect, useAlerts} from '@reasoncorp/kyber-js';

import {Parcel} from '../../types';
import {DenialRequest} from '../../types/request';
import {denialCreateSchema} from '../../schema/denial';
import * as messages from '../../messages';
import {denialApi} from '../../api';
import {CollectionContext} from '../../contexts';

type Props = {
  isOpen: boolean
  toggleOpen: () => void,
  parcel?: Parcel
  onSave?: () => void
}

const DenialCreateModal = ({
                             isOpen,
                             toggleOpen,
                             parcel,
                             onSave
                           }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const {collections} = useContext(CollectionContext);
  const navigate = useNavigate();

  const initialValues: DenialRequest = useMemo(() => ({
    parcel: {
      parcelNumber: parcel?.parcelNumber ?? '',
      countyId: parcel?.countyId ?? null,
      localUnit: parcel?.localUnit ?? '',
      address: {
        directionPrefix: parcel?.address.directionPrefix ?? '',
        directionSuffix: parcel?.address.directionSuffix ?? '',
        streetNumber: parcel?.address.streetNumber ?? '',
        streetName: parcel?.address.streetName ?? '',
        streetType: parcel?.address.streetType ?? '',
        unit: parcel?.address.unit ?? '',
        city: parcel?.address.city ?? '',
        state: 'MI',
        zip: parcel?.address.zip ?? ''
      }
    },
    majorReason: '',
    minorReason: ''
  }), [parcel]);

  const handleSave = useCallback(async (denialRequest: DenialRequest,
                                        formikHelpers: FormikHelpers<DenialRequest>) => {
    try {
      const denial = await denialApi.create(denialRequest);
      toggleOpen();
      showSuccessAlert(messages.DENIAL_SAVE_SUCCESSFUL);
      formikHelpers.resetForm();
      if (onSave) {
        onSave();
      } else {
        navigate(`/denials/${denial.id}`);
      }
    } catch (e) {
      showErrorAlert(messages.DENIAL_SAVE_FAILURE);
    }
  }, [showErrorAlert, navigate, onSave, showSuccessAlert, toggleOpen]);

  return <Formik initialValues={initialValues}
                 validateOnMount={true}
                 enableReinitialize={true}
                 onSubmit={handleSave}
                 validationSchema={denialCreateSchema}>
    {(formikProps) => (<>
      <Modal isOpen={isOpen}
             toggle={toggleOpen}
             autoFocus={false}
             aria-modal={true}
             returnFocusAfterClose={true}
             size="xl">
        <ModalHeader toggle={toggleOpen}
                     tag="h2"
                     className="h5 mb-0">
          Create New Denial
        </ModalHeader>
        <h3 className="h6 bg-light p-3" style={{borderBottom: '1px solid #EEE'}}>Property Information</h3>
        <Form onSubmit={formikProps.handleSubmit} autoComplete="off">
          <ModalBody>
            <Row>
              <Col sm="4">
                <FormikInput name="parcel.parcelNumber"
                             autoFocus
                             labelText="Parcel #"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="parcel.countyId"
                              labelText="County">
                  <option value="">Select</option>
                  {collections?.counties.map(county =>
                    <option value={county.id} key={county.id}>
                      {county.displayName}
                    </option>)
                  }
                </FormikSelect>
              </Col>
              <Col sm="4">
                <FormikInput name="parcel.localUnit"
                             labelText="City/Township"/>
              </Col>
            </Row>
            <Row>
              <Col sm="2">
                <FormikInput name="parcel.address.directionPrefix"
                             labelText="Dir. Prefix"/>
              </Col>
              <Col sm="2">
                <FormikInput name="parcel.address.streetNumber"
                             labelText="Street #"/>
              </Col>
              <Col sm="3">
                <FormikInput name="parcel.address.streetName"
                             labelText="Street Name"/>
              </Col>
              <Col sm="2">
                <FormikInput name="parcel.address.streetType"
                             labelText="Street Type"/>
              </Col>
              <Col sm="2">
                <FormikInput name="parcel.address.directionSuffix"
                             labelText="Dir. Suffix"/>
              </Col>
              <Col sm="1">
                <FormikInput name="parcel.address.unit"
                             labelText="APT #"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="parcel.address.city"
                             labelText="City"/>
              </Col>
              <Col sm="4">
                <FormikInput name="parcel.address.state"
                             disabled={true}
                             labelText="State Abbr"/>
              </Col>
              <Col sm="4">
                <FormikInput name="parcel.address.zip"
                             maxLength={10}
                             labelText="Zip Code"/>
              </Col>
            </Row>
          </ModalBody>
          <h3 className="h6 bg-light p-3"
              style={{
                borderBottom: '1px solid #EEE',
                borderTop: '1px solid #EEE'
              }}>Denial Information</h3>
          <ModalBody>
            <Row>
              <Col sm="6">
                <FormikSelect name="majorReason"
                              labelText="Major Reason">
                  <option value="">Select</option>
                  {collections?.majorReasons.map(majorReason =>
                    <option value={majorReason} key={majorReason}>{majorReason}</option>)
                  }
                </FormikSelect>
              </Col>
              <Col sm="6">
                <FormikSelect name="minorReason"
                              labelText="Minor Reason">
                  <option value="">Select</option>
                  {collections?.minorReasons.map(minorReason =>
                    <option value={minorReason} key={minorReason}>{minorReason}</option>)
                  }
                </FormikSelect>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="primary"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={toggleOpen}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>)}
  </Formik>;
};

export default DenialCreateModal;