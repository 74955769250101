import *  as Yup from 'yup';

export default Yup.object({
  name: Yup.string()
    .nullable(),
  firmName: Yup.string()
    .nullable(),
  address: Yup.string()
    .nullable(),
  city: Yup.string()
    .nullable(),
  state: Yup.string()
    .nullable(),
  zip: Yup.string()
    .nullable()
});