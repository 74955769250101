import * as Yup from 'yup';

import * as messages from '../../messages';

export default Yup.object({
  parcelAddress: Yup.string(),
  fileNumber: Yup.string(),
  parcelNumber: Yup.string(),
  ownerNames: Yup.string(),
  receivedOn: Yup.date()
    .typeError(messages.MUST_BE_VALID_DATE)
});