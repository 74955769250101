import * as messages from '../messages';
import {YearSeason} from '../types';

const uniqueNumberFilter = (value: number, index: number, self: number[]) => self.indexOf(value) === index;

const uniqueYearSeasonFilter = (value: YearSeason, index: number, self: YearSeason[]) =>
  self.findIndex(item => item.season === value.season && item.year === value.year) === index;

export const validateUniqueYears = function (this: any, yearsDenied: any) {
  const years = ((yearsDenied as {year?: number}[])
    ?.filter(yearDenied => !!yearDenied.year)
    .map(yearDenied => yearDenied.year) ?? []) as number[];
  const isValid = years.filter(uniqueNumberFilter).length === years.length;
  return isValid || this.createError({path: 'uniqueYearsErrorMessage', message: messages.MUST_BE_UNIQUE_YEARS});
};

export const validateUniqueSeasonPerYear = function (this: any, denialYears: any) {
  const years = ((denialYears as YearSeason[])
    ?.filter(denialYear => !!denialYear.year && !!denialYear.season)
    .map(denialYear => ({year: denialYear.year, season: denialYear.season})) ?? []) as YearSeason[];
  const isValid = years.filter(uniqueYearSeasonFilter).length === years.length;
  return isValid || this.createError({
    path: 'uniqueYearsErrorMessage',
    message: messages.MUST_BE_UNIQUE_SEASON_PER_YEAR
  });
};