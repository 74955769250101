import {BlobResponse} from '../types';
import {proServApi} from './apiUtils';

export const find = (reasonId: string, year: number): Promise<boolean> =>
  proServApi.getWithJsonResponse(
    `/scans?reasonId=${reasonId}&year=${year}`
  );

export const download = (reasonId: string, year: number): Promise<BlobResponse> =>
  proServApi.getWithBlobResponse(
    `/scans/download?reasonId=${reasonId}&year=${year}`
  );