import {Badge, Card, CardHeader} from 'reactstrap';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {RelatedParcelRecord} from '../../types';
import {CustomTable, useAlerts} from '@reasoncorp/kyber-js';
import {parcelApi} from '../../api';
import * as messages from '../../messages';
import {Link} from 'react-router-dom';

type Props = {
  parcelId: number
}

const RelatedParcelRecordsCard = ({
                                    parcelId
                                  }: Props) => {
  const [relatedParcelRecords, setRelatedParcelRecords] = useState<RelatedParcelRecord[]>([]);
  const {showErrorAlert} = useAlerts();

  useEffect(() => {
    const loadParcelRelatedRecords = async () => {
      try {
        const relatedParcelRecords = await parcelApi.findRelatedRecords(parcelId);
        setRelatedParcelRecords(relatedParcelRecords);
      } catch (e) {
        showErrorAlert(messages.API_FAILURE);
      }
    };

    void loadParcelRelatedRecords();
  }, [parcelId, showErrorAlert]);

  const getBadgeColor = useCallback((type: string) => {
    if (type === 'APPEAL') {
      return 'primary';
    } else if (type === 'DENIAL') {
      return 'secondary';
    } else if (type === 'BONA_FIDE') {
      return 'success';
    } else if (type === 'INTEREST_WAIVER') {
      return 'info';
    } else if (type === 'QUALIFIED_ERROR') {
      return 'warning';
    }
  }, []);

  const getRecordLink = useCallback((relatedParcelRecord: RelatedParcelRecord) => {
    if (relatedParcelRecord.type === 'APPEAL') {
      return `/appeals/${relatedParcelRecord.id}`;
    } else if (relatedParcelRecord.type === 'DENIAL') {
      return `/denials/${relatedParcelRecord.id}`;
    } else if (relatedParcelRecord.type === 'BONA_FIDE') {
      return `/bona-fides/${relatedParcelRecord.id}`;
    } else if (relatedParcelRecord.type === 'INTEREST_WAIVER') {
      return `/interest-waivers/${relatedParcelRecord.id}`;
    } else if (relatedParcelRecord.type === 'QUALIFIED_ERROR') {
      return `/qualified-errors/${relatedParcelRecord.id}`;
    } else {
      return '';
    }
  }, []);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Year', className: 'w-10'},
      {title: 'File Number', className: 'w-75'},
      {title: 'Type', className: 'w-15 text-center'}
    ],
    items: relatedParcelRecords,
    renderRow: (relatedParcelRecord: RelatedParcelRecord) => {
      return <tr key={relatedParcelRecord.fileNumber}>
        <td className="font-weight-bold text-primary">{relatedParcelRecord.year}</td>
        <td>
          <Link to={getRecordLink(relatedParcelRecord)}>
            {relatedParcelRecord.fileNumber}
          </Link>
        </td>
        <td>
          <Badge color={getBadgeColor(relatedParcelRecord.type)}
                 style={{width: '100%'}}>
            {relatedParcelRecord.typeDisplayValue}
          </Badge>
        </td>
      </tr>;
    },
    noResultsMessage: 'No related parcel records.'
  }), [relatedParcelRecords, getBadgeColor, getRecordLink]);

  return <Card className="hide-print">
    <CardHeader>Related Parcel Records</CardHeader>
    <CustomTable {...tableProps} />
  </Card>;
};

export default RelatedParcelRecordsCard;