import {BlobResponse, DenialResponse, DenialSearchResponse, DocumentResponse} from '../types';
import {DenialRequest, DenialSearchRequest} from '../types/request';
import {proServApi} from './apiUtils';

export const find = (id: number): Promise<DenialResponse> => proServApi.getWithJsonResponse(
  `/denials/${id}`
);

export const search = (denialSearchRequest: DenialSearchRequest): Promise<DenialSearchResponse[]> =>
  proServApi.postWithJsonResponse(
    '/denials/search',
    {
      body: JSON.stringify(denialSearchRequest)
    }
  );

export const create = (denialRequest: DenialRequest): Promise<DenialResponse> =>
  proServApi.postWithJsonResponse(
    `/denials`,
    {
      body: JSON.stringify(denialRequest)
    }
  );

export const save = (id: number, denialRequest: DenialRequest): Promise<DenialResponse> =>
  proServApi.putWithJsonResponse(
    `/denials/${id}`,
    {
      body: JSON.stringify(denialRequest)
    }
  );

export const findDocuments = (id: number): Promise<DocumentResponse[]> => proServApi.getWithJsonResponse(
  `/denials/${id}/documents`
);

export const downloadSearch = (denialSearchRequest: DenialSearchRequest): Promise<BlobResponse> =>
  proServApi.postWithBlobResponse(
    '/denials/search/download',
    {
      body: JSON.stringify(denialSearchRequest)
    }
  );