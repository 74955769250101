import {useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Modal, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik} from 'formik';

import {FormikInput, FormikSelect, useUserContext, UsState} from '@reasoncorp/kyber-js';

import {interestWaiverAssessorSchema} from '../../schema/interestWaiver';
import {InterestWaiverResponse} from '../../types';
import {InterestWaiverAssessorRequest, InterestWaiverRequest} from '../../types/request';

type Props = {
  interestWaiver: InterestWaiverResponse
  onSave: (interestWaiverRequest: InterestWaiverRequest) => void
}

const InterestWaiverAssessorInformationCard = ({
                                                 interestWaiver,
                                                 onSave
                                               }: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {permissions} = useUserContext();

  const handleSave = async (interestWaiverAssessorRequest: InterestWaiverAssessorRequest) => {
    const interestWaiverRequest = {
      ...interestWaiver,
      assessor: interestWaiverAssessorRequest
    } as InterestWaiverRequest;
    await onSave(interestWaiverRequest);
    setModalIsOpen(false);
  };

  const initialValues: InterestWaiverAssessorRequest = {
    firstName: interestWaiver.assessor.firstName,
    lastName: interestWaiver.assessor.lastName,
    mailingAddress: interestWaiver.assessor.mailingAddress
  };

  return <>
    <Card>
      <CardHeader>
        <Row>
          <Col sm="6" className="align-self-center">
            Assessor Information
          </Col>
          <Col sm="6" className="justify-content-end d-flex">
            {permissions.hasWriteAccess && <Button color="primary"
                                                   onClick={() => setModalIsOpen(true)}
                                                   size="sm">
              Edit
            </Button>}
          </Col>
        </Row>
      </CardHeader>
      <Table responsive bordered>
        <thead>
          <tr>
            <th className="w-40">Name</th>
            <th className="w-60">Address</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{interestWaiver.assessor.name}&nbsp;</td>
            <td>{interestWaiver.assessor.mailingAddress?.fullDisplay}&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </Card>
    <Modal isOpen={modalIsOpen}
           toggle={() => setModalIsOpen(!modalIsOpen)}
           autoFocus={false}
           aria-modal={true}
           returnFocusAfterClose={true}
           size="lg">
      <ModalHeader toggle={() => setModalIsOpen(!modalIsOpen)}
                   tag="h2"
                   className="h5">
        Edit Assessor Information
      </ModalHeader>
      <Formik initialValues={initialValues}
              validateOnMount={true}
              enableReinitialize={true}
              onSubmit={handleSave}
              validationSchema={interestWaiverAssessorSchema}>
        {(formikProps) => (<>
          <CardBody>
            <Row>
              <Col sm="6">
                <FormikInput name="firstName"
                             labelText="Assessor First Name"
                             autoFocus/>
              </Col>
              <Col sm="6">
                <FormikInput name="lastName"
                             labelText="Assessor Last Name"/>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikInput name="mailingAddress.address"
                             labelText="Mailing Street"/>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <FormikInput name="mailingAddress.city"
                             labelText="City"/>
              </Col>
              <Col sm="4">
                <FormikSelect name="mailingAddress.state"
                              labelText="State">
                  <option value="">Select</option>
                  {Object.keys(UsState).map(state =>
                    <option value={state} key={state}>{state}</option>)}
                </FormikSelect>
              </Col>
              <Col sm="4">
                <FormikInput name="mailingAddress.zip"
                             labelText="Zip"/>
              </Col>
            </Row>
          </CardBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button color="success"
                    className="mr-2"
                    disabled={formikProps.isSubmitting || !formikProps.dirty || !formikProps.isValid}
                    onClick={formikProps.submitForm}>
              Save
            </Button>
            <Button color="secondary"
                    onClick={() => setModalIsOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </>)}
      </Formik>
    </Modal>
  </>;
};

export default InterestWaiverAssessorInformationCard;