import {ExportBatchLite, ExportBatchReport, PendingExport} from '@reasoncorp/kyber-js';

import {proServApi} from './apiUtils';

export const findBatch = (id: number): Promise<ExportBatchReport> => proServApi.getWithJsonResponse(
  `/imports/batches/${id}`
);

export const findBatches = (): Promise<ExportBatchLite[]> => proServApi.getWithJsonResponse(
  `/imports/batches`
);

export const findPending = (): Promise<PendingExport[]> => proServApi.getWithJsonResponse(
  `/imports/pending`
);
